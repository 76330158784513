<template>
  <PageWithLayout :is-back-btn="true" :is-back-web-end="true">
    <div class="container">
      <div class="head">
        <strong class="title tc_black"
          >목표 금액을 정하고<br />꾸준히 모아보세요!</strong
        >
        <span class="desc tc_gray">부모님에게 저금 보너스도 받아보아요</span>
      </div>
      <div class="list">
        <div ref="list_inner" class="inner">
          <div ref="list_group" :class="['list-group']">
            <ListItem
              v-for="(item, index) in list"
              :key="String(index)"
              :title="item.title"
              :price="item.price"
              :thumbnail="item.thumbnail"
            />
          </div>
        </div>
      </div>
      <div ref="bottom" class="bottom">
        <Button
          btn-style="primary"
          text="저금통 만들기"
          @onClickBtn="onClickPageMovePiggyBankMaking"
        />
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import Button from '@/components/common/button/Button';
// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';
import ListItem from './components/ListItem.vue';

import { list } from '../model/list';

import { PageMoveParam } from '@/helpers/firfinInterface';

export default {
  name: 'PiggBankIntroduction',
  components: {
    PageWithLayout,
    Button,
    ListItem,
  },
  mixins: [InterFaceMixin, BackKeyEventMixin],
  data() {
    return {
      list: [...list, ...list, ...list],
    };
  },
  // mounted() {
  //   const area =
  //     this.$refs.list_inner.clientHeight - this.$refs.bottom.clientHeight;

  //   const target = this.$refs.list_group;

  //   if (area >= target.clientHeight) {
  //     return;
  //   }

  //   // Vue.js 컴포넌트에서 스타일 변경에는 this.$nextTick을 사용해야 합니다.
  //   this.$nextTick(() => {
  //     target.style.top = `-${target.clientHeight - area}px`;
  //   });
  // },
  methods: {
    onClickBackKey() {
      this.webEnd();
    },
    onClickPageMovePiggyBankMaking() {
      this.pageMove(PageMoveParam.savingsCreate);
    },
  },
};
</script>

<style scoped>
.container {
  height: calc(100vh - 54px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.head {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, #fff 9.9%);
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 20px 48px;
  z-index: 9999;
}

.head .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px; /* 125% */
}

.head .desc {
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
}

.list {
  padding-top: 160px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 108px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.list .inner {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.list .inner .list-group {
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: absolute;
  animation: scrollAnimation 10s linear 0.5s infinite;
}

@keyframes scrollAnimation {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-560px);
    /* 수치 동적으로 주는 방법을 모르겠음.. */
  }
}

.bottom {
  padding: 20px 20px 32px;
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 9.9%);
}
</style>
