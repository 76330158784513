<template>
  <div>
    <div class="container">
      <img :src="thumbnail.src" :alt="thumbnail.alt" />
      <div class="area-right">
        <span class="title tc_gray">{{ title }}</span>
        <strong class="price tc_black">{{ priceText }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    thumbnail: {
      src: String,
      alt: String,
    },
    title: String,
    price: Number,
  },
  computed: {
    priceText() {
      return `${this.price.toLocaleString()}원`;
    },
  },
};
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

img {
  width: 80px;
  height: 80px;
}

.area-right {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.price {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px; /* 140% */
}
</style>
