export var list = [
    {
        thumbnail: {
            src: '/assets/images/piggyBank/introduction/img_1.png',
            alt: '뉴진스 앨범',
        },
        title: '뉴진스 앨범',
        price: 24000,
    },
    {
        thumbnail: {
            src: '/assets/images/piggyBank/introduction/img_2.png',
            alt: '다이소 털기',
        },
        title: '다이소 털기',
        price: 50000,
    },
    {
        thumbnail: {
            src: '/assets/images/piggyBank/introduction/img_3.png',
            alt: '엄마 생신선물',
        },
        title: '엄마 생신선물',
        price: 30000,
    },
    {
        thumbnail: {
            src: '/assets/images/piggyBank/introduction/img_4.png',
            alt: '친구들과 놀러가기',
        },
        title: '친구들과 놀러가기',
        price: 50000,
    },
    {
        thumbnail: {
            src: '/assets/images/piggyBank/introduction/img_5.png',
            alt: '애플워치',
        },
        title: '애플워치',
        price: 300000,
    },
];
